import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ArrowR from '@material-ui/icons/ArrowForward'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Body2 from '../components/Body2'
import Paragraph from '../components/Paragraph'
import Subtitle from '../components/SubTitle2'
import OutlineButton from '../components/OutlinedButton'

import { Container } from '../components/styles/Layout.styles'
import { btnLarge } from '../components/styles/OutlineButton.styles'
import { 
  styledDomains,
  header,
  domainsList,
  IdProtectBox,
  IdProtectList,
  legend
} from '../components/styles/Domains.styles'
import { termosBox } from '../components/styles/Contact.styles'
import DominiosForm from '../components/ContactForm2'

const fields = [
  {
    label: 'Email de acesso do CORE',
    name: 'your-email',
    type: 'text',
    required: true
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'email',
    required: true
  },
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Endereço',
    name: 'your-adress',
    type: 'text',
    required: true
  },
  {
    label: 'Número',
    name: 'your-number',
    type: 'text',
    required: true
  },
  {
    label: 'Complemento',
    name: 'your-complement',
    type: 'text',
    required: true
  },
  {
    label: 'Cidade',
    name: 'your-city',
    type: 'text',
    required: true
  },
  {
    label: 'Estado',
    name: 'your-state',
    type: 'text',
    required: true
  },
  {
    label: 'CEP',
    name: 'your-cep',
    type: 'text',
    required: true
  },
  {
    label: 'País',
    name: 'your-country',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Login',
    name: 'your-login',
    type: 'text',
    required: true
  },
  {
    label: 'Senha',
    name: 'your-password',
    type: 'password',
    required: true
  },
  {
    label: 'Comentários',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledDomains}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>domínios</span><br />
                Tenha um endereço eletrônico e marque seu espaço na web.
              </H4>
              <Paragraph style={{ marginTop: 16 }}>Atualmente é imprescindível ter um endereço próprio na web para mostrar profissionalismo e inspirar confiança. Registre conosco o seu domínio internacional. Livre para registro de pessoas físicas e jurídicas.</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={['left', 'left', 'center']}
          >
            <Column>
              <H5>Registro de Domínios</H5>
            </Column>
          </Row>
          <Row
            flexDirection={['column', 'row']}
            mt={14}
            css={domainsList}
          >
            <Div
              maxWidth={['100%', 422, 680, 684]}
              flexDirection={['column', 'column', 'row']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={16}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Div>
                  <H6>.com</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
                <Div>
                  <H6>.com.br</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={16}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Div>
                  <H6>.net</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
                <Div>
                  <H6>.info</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
              </Column>
            </Div>
          </Row>
          <Row
            flexDirection={['column', 'row']}
            css={domainsList}
          >
            <Div
              maxWidth={['100%', 422, 680, 684]}
              flexDirection={['column', 'column', 'row']}
              style={{
                margin: '0 auto',
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={16}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Div>
                  <H6>.org</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
                <Div>
                  <H6>.biz</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={16}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Div>
                  <H6>.tv</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
                <Div>
                  <H6>.ltda</H6>
                  <Body2>R$ 49,00/ano</Body2>
                </Div>
              </Column>
            </Div>
          </Row>
          <Row>
            <Column
              width={1}
              mt={34}
              mb={50}
            >
              <a
                href='https://core.hostdime.com.br/purchasedomain/index'
                target='_blank'
              >
                <div
                  css={btnLarge}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto'
                  }}
                >
                  solicite agora o registro
                </div>
              </a>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          {/* <Row
            css={header}
            justifyContent={'center'}
            flexDirection={'column'}
            mt={[40, 45.5, 39.5, 39.5]}
            mb={50}
          >
            <Column
              width={1}
              mb={30}
            >
              <H5 style={{ marginBottom: 8 }}>Transferência de Domínios</H5>
              <Subtitle style={{ marginBottom: 16 }}>Transfira o seu domínio internacional<br /> (.COM, .NET, .ORG, .INFO, .BIZ) para a HostDime</Subtitle>
              <Paragraph style={{ textAlign: 'left', marginBottom: 16 }}>Para a transferência do seu domínio é cobrada uma taxa a partir de R$59,00, de acordo com o tipo de seu domínio, o mesmo será renovado por mais um ano. Para este processo você deverá destravar o domínio no atual órgão de registro e obter a chave de transferência (EPP-KEY). Lembramos que domínios registrados com menos de 60 dias não poderão ser transferidos neste período.</Paragraph>
              <Paragraph style={{ textAlign: 'left', marginBottom: 16 }}>Será enviado um e-mail, para o endereço de contato administrativo do domínio, referente a aprovação da transferência. Após aprovado, o domínio será transferido para nosso órgão de registro em até 7 dias, concluído esta etapa, você poderá gerenciar o domínio através de sua área de cliente conosco.</Paragraph>
              <Paragraph style={{ textAlign: 'left' }}>Atenção: Nós não recomendamos a transferência de domínios que estão com data de registro próxima de expiração, ficando assim de responsabilidade do cliente. Em casos de dúvidas, entre em contato com a nossa equipe através dos nossos canais de atendimento.</Paragraph>
            </Column>
            <Column
              width={1}
            >
              <a
                href='https://core.hostdime.com.br/purchasedomain/index/domainTransfer/1'
                target='_blank'
              >
                <div
                  css={btnLarge}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto'
                  }}
                >
                  solicite a transferência 
                </div>
              </a>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%', 680, 680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div> */}
          <Row
            css={header}
            justifyContent={'center'}
            flexDirection={'column'}
            mt={[40, 45.5, 39.5, 39.5]}
          >
            <Column
              width={1}
              mb={30}
            >
              <H5 style={{ marginBottom: 8 }}>Revenda de Domínios</H5>
              <Subtitle style={{ marginBottom: 16 }}>Tenha uma revenda de domínios e acrescente mais valor ao seu negócio de hospedagem.</Subtitle>
              <Paragraph style={{ marginBottom: 16 }}>Ao assinar qualquer plano conosco, tenha acesso a ferramenta de revenda de domínios. Revenda domínios diretamente para o seu cliente, sem intermediários e com a possibilidade de cadastrar sub-revendedores.</Paragraph>
              <Paragraph style={{ marginBottom: 30 }}>A HostDime fez uma parceria com a Enom, uma das principais empresas de registro de domínios do mundo, para lhe oferecer uma solução acessível de revenda de domínios. Com uma conta de revenda Enom você poderá registrar domínios para os seus clientes, e fornecer-lhes um painel de controle próprio para a administração de seus domínios.</Paragraph>
              <Subtitle>Tenha já o seu cadastro do Enom. <br />Preencha o fomulário abaixo e aguarde a confirmação da nossa equipe:</Subtitle>
            </Column>
          </Row>
          <Row
            mb={[40, 0, 50]}
            flexDirection={['column']}
          >
            <Column
              width={[1]}
            >
              <Div
                alignItems={['center']}
                display={['flex']}
              >
                <DominiosForm
                  fields={fields}
                  formId={36}
                  submitText='Enviar'
                />
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%', 680, 680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            css={header}
            justifyContent={'center'}
            flexDirection={'column'}
            mt={[40, 45.5, 39.5, 39.5]}
          >
            <Column
              width={1}
            >
              <H5 style={{ marginBottom: 8 }}>ID Protect</H5>
              <Subtitle>Seu escudo contra spam e roubo de identidade.</Subtitle>
            </Column>
          </Row>
          <Row
            mt={30}
            mb={46}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              css={IdProtectBox}>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Protege suas informações pessoais online</span>
                </div>
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Reduz spam para sua caixa de entrada</span>
                </div>
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Proteção 24 horas por dia, 365 dias por ano</span>
                </div>
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Só nos Estados Unidos há cerca de 9 milhões de casos de roubo de identidade e 3 trilhões de e-mails de spam enviados a cada ano. Spammers e ladrões podem obter as suas informações através de registro público (WHOIS) do seu nome de domínio.</span>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>ID Protect mantém seguras as informações do seu domínio em registros públicos por meio da "privatização", retira todos os traços de suas informações pessoais, fornece informações de proxy em vez de suas informações de contato pessoal.</span>
                </div>
              </Column>
            </Div>
          </Row>
          <Row
            justifyContent={['left', 'left', 'center', 'center']}
          >
            <Column
              mb={50}
            >
              <div css={legend} style={{ marginBottom: 24 }}>*Serviço disponível apenas para domínios registrados com a HostDime Brasil.</div>
              <div css={legend}>**Para contratação, pedimos que entre em contato através dos nossos canais de atendimento em nosso setor de suporte Financeiro.</div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            mb={50}
          >
            <Column
              width={1}
              justifyContent={'center'}
              display={'flex'}
            >
              <OutlineButton
                type='btnOutlinedLarge'
                Link='/contatos'
              >
                entre em contato
              </OutlineButton>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%', 680, 680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            css={header}
            justifyContent={'center'}
            flexDirection={'column'}
            mt={[40, 45.5, 39.5, 39.5]}
          >
            <Column
              width={1}
              mb={30}
            >
              <H5 style={{ marginBottom: 8 }}>Certificado SSL</H5>
              <Subtitle style={{ marginBottom: 16 }}>SSL 128-Bit e 256-Bit<br /> Proteja o seu site e ganhe a confiança.</Subtitle>
              <Paragraph>A HostDime atualmente disponibiliza 5 tipos de certificados SSL. Através de nossa parceira com a Comodo, nós oferecemos certificados SSL 128-Bit e 256-Bit padrão para manter a segurança de seu domínio. Também oferecemos um certificado Wild Card que pode garantir a segurança de todos os seus subdomínios.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Domínios HostDime | Registre seu site'
        description='Tenha um endereço próprio na web e mostre mais profissionalismo e confiança no seu site. Registre seu domínio internacional com nossos parceiros.'
      />
      <PageTemplate
        title='Registro de Domínios'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "img-step-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
